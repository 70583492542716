const localeStorageKey = 'cf-studio-dark-mode'

const validStates = new Set(['dark', 'light'])

export function initDarkMode() {
  const preference = getPreference()

  setPreference(preference)
}

export function getPreference() {
  const storageValue = localStorage.getItem(localeStorageKey)

  if (validStates.has(storageValue!)) {
    return storageValue as 'dark' | 'light'
  }

  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

  return isDarkMode ? 'dark' : 'light'
}

export function setPreference(state: 'dark' | 'light') {
  if (state === 'light') {
    localStorage.setItem(localeStorageKey, state)
    document.documentElement.classList.remove('dark')
    return
  }

  localStorage.setItem(localeStorageKey, state)
  document.documentElement.classList.add('dark')
  return
}
