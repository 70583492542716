import * as React from 'react'
import { Outlet, matchPath, useLocation } from 'react-router-dom'
import { lazyComponent } from 'utils/lazy-component'
import { usePaymentsCompleteScreenDialog } from 'payments-dialog/hooks/use-payments-complete-screen-dialog'
import { PageLoader } from '@studio/components/page-loader'
import * as Dialog from 'primitives/dialog'
import { ROUTES } from '@studio/routes'

const PaymentsCompleteScreenDialog = lazyComponent(() =>
  import('payments-dialog').then(m => m.PaymentsCompleteScreenDialog),
)
const MobileGuard = lazyComponent(() =>
  import('@studio/components/mobile-guard').then(m => m.MobileGuard),
)

// these are the pages/apps that are already mobile responsive
export const RESPONSIVE_ROUTES = [
  {
    path: ROUTES.STUDIO.ROOT,
    exact: true,
  },
  {
    path: ROUTES.FLOW.ROOT,
    exact: false,
  },
  {
    path: ROUTES.TRANSFER.ROOT,
    exact: false,
  },
]

export function Root() {
  const { getRootProps } = usePaymentsCompleteScreenDialog()
  const { pathname } = useLocation()

  const isPageResponsive = React.useMemo(
    () =>
      RESPONSIVE_ROUTES.some(({ path, exact }) =>
        exact ? matchPath(path, pathname) : pathname.includes(path),
      ),
    [pathname],
  )

  return (
    <>
      <React.Suspense fallback={<PageLoader />}>
        {isPageResponsive ? null : <MobileGuard.component />}
        <Outlet />
        <React.Suspense fallback={null}>
          <Dialog.Root {...getRootProps()}>
            <Dialog.Portal forceMount>
              <Dialog.Body zIndex={80}>
                <PaymentsCompleteScreenDialog.component />
              </Dialog.Body>
            </Dialog.Portal>
          </Dialog.Root>
        </React.Suspense>
      </React.Suspense>
    </>
  )
}
