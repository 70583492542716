import algoliasearch from 'algoliasearch'
import { unstable_cache } from 'next/cache'
import { invariant } from 'utils/invariant'
import { ContentLanguage } from 'codegen/types'
import algoliaAnalytics from 'search-insights'
import ms from 'ms'

const env = (value: string | undefined, message: string): string => {
  invariant(value, message)
  return value
}

export const algoliaAppId = env(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || process.env.VITE_ALGOLIA_APP_ID,
  'NEXT_PUBLIC_ALGOLIA_APP_ID or VITE_ALGOLIA_APP_ID is not set',
)
export const algoliaApiKey = env(
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || process.env.VITE_ALGOLIA_API_KEY,
  'NEXT_PUBLIC_ALGOLIA_API_KEY or VITE_ALGOLIA_API_KEY is not set',
)
export const algoliaTemplatesIndex = env(
  process.env.NEXT_PUBLIC_ALGOLIA_TEMPLATES_INDEX || process.env.VITE_ALGOLIA_TEMPLATES_INDEX,
  'NEXT_PUBLIC_ALGOLIA_TEMPLATES_INDEX or VITE_ALGOLIA_TEMPLATES_INDEX is not set',
)
export const algoliaContentIndex = env(
  process.env.NEXT_PUBLIC_CONTENT_ALGOLIA_INDEX || process.env.VITE_ALGOLIA_INDEX,
  'NEXT_PUBLIC_CONTENT_ALGOLIA_INDEX or VITE_ALGOLIA_INDEX is not set',
)
export const algoliaProductIndex = env(
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX || process.env.VITE_ALGOLIA_PRODUCT_INDEX,
  'NEXT_PUBLIC_CONTENT_ALGOLIA_INDEX or VITE_ALGOLIA_INDEX is not set',
)
export const algoliaContentIndexNewest = `${algoliaContentIndex}${
  algoliaContentIndex.includes('trending') ? '' : '_trending'
}_newest`
export const algoliaContentIndexOldest = `${algoliaContentIndex}${
  algoliaContentIndex.includes('trending') ? '' : '_trending'
}_oldest`
export const algoliaProductsIndexNewest = `${algoliaProductIndex}${
  algoliaProductIndex.includes('trending') ? '' : '_trending'
}_newest`
export const algoliaProductsIndexOldest = `${algoliaProductIndex}${
  algoliaProductIndex.includes('trending') ? '' : '_trending'
}_oldest`

export const productsAlgoliaIndex = env(
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX || process.env.VITE_PRODUCTS_ALGOLIA_INDEX,
  'NEXT_PUBLIC_ALGOLIA_INDEX OR VITE_PRODUCTS_ALGOLIA_INDEX is not set',
)

export const defaultSearchClient = algoliasearch(algoliaAppId, algoliaApiKey, {
  headers:
    typeof window === 'undefined'
      ? {
          referer: 'https://www.creativefabrica.com',
        }
      : {},
})

const index = defaultSearchClient.initIndex(algoliaTemplatesIndex)

export const getCachedObjectById = unstable_cache(
  async id => getObjectById(id),
  ['getObjectById'],
  { revalidate: 86400 },
)

export async function getObjectById(objectId: string) {
  try {
    return await index.getObject(objectId)
  } catch (error) {
    return null
  }
}

export const mapLocaleToContentLanguage = new Map<string, ContentLanguage>([
  ['en', ContentLanguage.LanguageEn],
  ['es', ContentLanguage.LanguageEs],
  ['de', ContentLanguage.LanguageDe],
  ['fr', ContentLanguage.LanguageFr],
  ['it', ContentLanguage.LanguageIt],
  ['nl', ContentLanguage.LanguageNl],
  ['pt', ContentLanguage.LanguagePtBr],
  ['pl', ContentLanguage.LanguagePl],
])

export const mapContentLanguageToLocale = new Map<ContentLanguage, string>([
  [ContentLanguage.LanguageEn, 'en'],
  [ContentLanguage.LanguageEs, 'es'],
  [ContentLanguage.LanguageDe, 'de'],
  [ContentLanguage.LanguageFr, 'fr'],
  [ContentLanguage.LanguageIt, 'it'],
  [ContentLanguage.LanguageNl, 'nl'],
  [ContentLanguage.LanguagePtBr, 'pt'],
  [ContentLanguage.LanguagePl, 'pl'],
])

export const DEFAULT_ALGOLIA_LANGUAGE = ContentLanguage.LanguageEn

export type AlgoliaTranslatedString = Record<ContentLanguage, string>

export type AlgoliaContentHit = {
  __position: number
  __queryID: string
  _highlightResult?: {
    title: Record<ContentLanguage, { value: string }>
  }
  authorId: string
  objectID: string
  slug: AlgoliaTranslatedString
  thumbnail: { url: string; width: number; height: number }
  previewMobile: { url: string; width: number; height: number }
  previewDesktop: { url: string; width: number; height: number }
  title: AlgoliaTranslatedString
}

export type AlgoliaSearchedProductsLocalStorage = {
  items: Record<string, Record<string, string>>
  expiresAt: Date
}

algoliaAnalytics('init', {
  appId: algoliaAppId,
  apiKey: algoliaApiKey,
  useCookie: true,
  cookieDuration: ms('90 days'),
})

export enum AlgoliaEventName {
  PRODUCT_CLICKED = 'Product Clicked',
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_DOWNLOADED = 'Product Downloaded',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  PRODUCTS_SEARCHED = 'Products Searched',
}
