import { getUser, getUserAuth } from './auth'

/**
 * This function is used to preload the root page before any routes are loaded.
 * This means we can eagerly load the user data before the user navigates or any routes are loaded for ⚡️
 */
export function preloadRoot() {
  getUserAuth()
  getUser()
  return null
}
