import { vectorizerGetImageQueryOptions } from '@studio/gen/vectorizer'
import { queryClient } from '@studio/query-client'
import { ROUTES } from '@studio/routes'
import { Root } from '@studio/routes/root'
import { getIsLoggedIn, getUserId } from '@studio/utils/auth'
import { getObjectById } from 'algolia/algolia'
import {
  useStudioProjectsCreateProjectMutation,
  useStudioProjectsGetProjectQuery,
} from 'codegen/generated/projects'
import { useTeamsGetQuery } from 'codegen/generated/teams'
import { ProjectEditor } from 'codegen/types'
import ms from 'ms'
import {
  Outlet,
  createBrowserRouter,
  redirect,
  type Params,
  type RouteObject,
} from 'react-router-dom'
import { lazyComponent } from 'utils/lazy-component'
import { replacePathname, stripDomain, stripQueryParams } from 'utils/url'

import { saveStudioDataQueryParam } from './env'
import { ENABLE_FABRIC } from './routes/studio/utils/editor'
import { preloadRoot } from './utils/preload'

const ErrorPage = lazyComponent(() => import('@studio/routes/error-page').then(m => m.ErrorPage))

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    loader: preloadRoot,
    errorElement: <ErrorPage.component />,
    children: [
      {
        // https://creativefabrica.atlassian.net/browse/STU-660
        // if a URL ends in "/ref/:id" we need to log that as a referral
        path: ':path?/ref/:id',
        loader: async ({ params, request, context }) => {
          if (context?.__fromPreloadRoute) {
            return null
          }

          const { id } = params
          if (id) {
            const captureReferral = await import('utils/affiliate-tracking').then(
              m => m.captureReferral,
            )
            await captureReferral(id).catch(() => null)
          }

          //Remove the campaign query param from the URL if present
          const urlSegments = request.url.split('?')
          let urlSearchParamsString = ''
          if (urlSegments.length > 1) {
            const urlSearchParams = new URLSearchParams(`?${urlSegments[1]}`)
            const campaign = urlSearchParams.get('campaign')
            if (campaign) {
              urlSearchParams.delete('campaign')
            }
            urlSearchParamsString = urlSearchParams.toString()
          }
          const nextUrl = `${urlSegments[0].replace(`/ref/${id}`, '')}${
            urlSearchParamsString.length > 0 ? `?${urlSearchParamsString}` : ''
          }`

          return redirect(nextUrl)
        },
      },
      {
        path: '*',
        lazy: () => import('@studio/routes/404'),
      },
      {
        path: '/',
        children: [
          {
            index: true,
            lazy: () => import('@studio/routes/studio/routes/home'),
          },
        ],
      },
      {
        path: 'healthcheck',
        lazy: () => import('@studio/routes/health-check'),
      },
      {
        path: '/flow',
        lazy: () => import('@studio/routes/flow'),
        children: [
          {
            path: ':id',
            lazy: () => import('@studio/routes/flow'),
          },
        ],
      },
      {
        path: '/transfer',
        children: [
          {
            path: ':id',
            lazy: () => import('@studio/routes/transfer/download/'),
          },
          {
            path: 'expired',
            lazy: () => import('@studio/routes/transfer/expired'),
          },
          {
            path: '',
            lazy: () => import('@studio/routes/transfer/ad-view'),
          },
        ],
      },
      {
        path: 'design-reviewer',
        lazy: () => import('@studio/routes/design-reviewer'),
        children: [
          {
            path: '',
            lazy: () => import('@studio/routes/design-reviewer/upload'),
          },
          {
            path: ':id',
            lazy: () => import('@studio/routes/design-reviewer/result'),
          },
        ],
      },
      {
        path: 'background-remover',
        lazy: () => import('@studio/routes/background-remover'),
        children: [
          {
            path: '',
            lazy: () => import('@studio/routes/background-remover/upload'),
          },
          {
            path: ':id',
            lazy: () => import('@studio/routes/background-remover/result'),
            // for the loader, look in `@studio/routes/background-remover/result`
          },
        ],
      },
      {
        path: 'vectorizer',
        lazy: () => import('@studio/routes/vectorizer'),
        children: [
          {
            path: '',
            lazy: () => import('@studio/routes/vectorizer/upload'),
          },
          {
            path: ':id',
            lazy: () => import('@studio/routes/vectorizer/result'),
            loader: async ({ params, context }) => {
              const { id = '' } = params
              const variables = {
                input: {
                  id,
                },
              }

              const { vectorizerGetImage } = await queryClient.fetchQuery(
                vectorizerGetImageQueryOptions(variables),
              )

              if (context?.__fromPreloadRoute) {
                return null
              }

              const { image, errors } = vectorizerGetImage

              if (errors?.length || image?.status === 'deleted' || image?.status === 'failed') {
                return redirect(ROUTES.VECTORIZER.ROOT)
              }

              return null
            },
          },
        ],
      },
      {
        path: 'invites',
        children: [
          {
            index: true,
            loader: () => {
              return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
            },
          },
          {
            path: 'accept',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/accept'),
          },
          {
            path: 'approve-member',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
          {
            path: 'approve-project-member',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
          {
            path: 'approve-role-change',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
          {
            path: 'approve-project-member-role-change',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
        ],
      },
      {
        path: 'studio',
        children: [
          {
            index: true,
            loader: () => {
              return redirect(ROUTES.STUDIO.HOME)
            },
          },
          {
            path: 'trash',
            lazy: () => import('@studio/routes/studio/routes/trash'),
          },
          {
            path: 'shared-projects',
            lazy: () => import('@studio/routes/studio/routes/shared-projects'),
            children: [
              {
                path: '',
                lazy: () => import('@studio/routes/studio/routes/shared-projects/shared-all'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
              {
                path: 'teams',
                lazy: () => import('@studio/routes/studio/routes/shared-projects/teams'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
              {
                path: 'users',
                lazy: () => import('@studio/routes/studio/routes/shared-projects/users'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
            ],
          },
          {
            path: 'projects',
            lazy: () => import('@studio/routes/studio/routes/projects'),
            children: [
              {
                path: '',
                lazy: () => import('@studio/routes/studio/routes/projects/projects-all'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
            ],
          },
          {
            path: 'folders',
            lazy: () => import('@studio/routes/studio/routes/projects'),
            children: [
              {
                index: true,
                loader: () => {
                  return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
                },
              },
              {
                path: ':folderId',
                lazy: () => import('@studio/routes/studio/routes/projects/folder'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
            ],
          },
          {
            path: 'teams',
            lazy: () => import('@studio/routes/studio/routes/teams'),
            children: [
              {
                index: true,
                loader: () => {
                  return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
                },
              },
              {
                path: ':teamId',
                lazy: () => import('@studio/routes/studio/routes/teams/home'),
                loader: redirectIfNotInTeam,
              },
              {
                path: ':teamId/users',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/users'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
              {
                path: ':teamId/templates',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/templates'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
              {
                path: ':teamId/brand-hub',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/brand-hub'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
              {
                path: ':teamId/trash',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/trash'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
            ],
          },
          {
            path: 'teams/:teamId',
            lazy: () => import('@studio/routes/studio/routes/teams/projects'),
            children: [
              {
                path: 'projects',
                lazy: () => import('@studio/routes/studio/routes/teams/projects/projects-all'),
                loader: redirectIfNotInTeam,
              },
              {
                path: 'folders',
                children: [
                  {
                    index: true,
                    loader: () => {
                      return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
                    },
                  },
                  {
                    path: ':folderId',
                    lazy: () => import('@studio/routes/studio/routes/teams/projects/folder'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
            ],
          },
          {
            path: ENABLE_FABRIC ? 'create' : 'fabricate',
            lazy: () => import('@studio/routes/studio/routes/fabricate'),
            children: [
              {
                path: '',
                element: <Outlet />,
                loader: async ({ params: _params, request, context }) => {
                  if (context?.__fromPreloadRoute) {
                    return null
                  }

                  const { searchParams } = new URL(request.url)

                  // if we are coming in to load a template, let's forward them to /studio/create or /studio/class
                  if (
                    searchParams.get('template_id') ||
                    searchParams.get('content_template_id') ||
                    searchParams.get('template_category') ||
                    searchParams.get(saveStudioDataQueryParam) === 'true'
                  ) {
                    if (ENABLE_FABRIC) {
                      return redirect(`/studio/classic?${searchParams.toString()}`)
                    } else {
                      return redirect(`/studio/create?${searchParams.toString()}`)
                    }
                  }

                  const isLoggedIn = await getIsLoggedIn().catch(() => null)

                  // you can use the editor if you are not logged in without creating a new project
                  if (!isLoggedIn) {
                    return null
                  }

                  const content = await import(
                    './components/new-design-button/context/document-categories'
                  ).then(m => m.PARTNER_CATEGORIES[0].templates[0])

                  // the index path should always create a new project for you if you are logged in
                  const response = await useStudioProjectsCreateProjectMutation
                    .fetcher({
                      input: {
                        editor: ProjectEditor.Fabric,
                        // todo: currently backend needs a content field for fabric even tho
                        // the projects are saved elsewhere.
                        content: JSON.stringify(content),
                      },
                    })()
                    .catch(() => null)

                  if (!response?.studioProjectsCreateProject?.project?.id) {
                    return {
                      error: true,
                    }
                  }

                  return redirect(
                    `./${
                      response.studioProjectsCreateProject.project.id
                    }?${searchParams.toString()}`,
                  )
                },
              },
              {
                path: ':id',
                id: 'fabricate-project',
                loader: async ({ params, context }) => {
                  if (context?.__fromPreloadRoute) {
                    return null
                  }
                  if (!params.id) {
                    return {
                      error: true,
                    }
                  }
                  const variables = {
                    projectId: params.id,
                  }

                  const project = await queryClient
                    .fetchQuery({
                      queryFn: useStudioProjectsGetProjectQuery.fetcher(variables),
                      queryKey: useStudioProjectsGetProjectQuery.getKey(variables),
                      staleTime: ms('15s'),
                    })
                    .catch(() => null)

                  if (
                    project?.studioProjectsGetProject.project?.activeRevision?.editor ===
                    ProjectEditor.Polotno
                  ) {
                    if (ENABLE_FABRIC) {
                      return redirect(`/studio/classic/${params.id}`)
                    } else {
                      return redirect(`/studio/create/${params.id}`)
                    }
                  }
                  if (project?.studioProjectsGetProject.errors?.[0]?.code === 'unauthorized') {
                    return redirect(ROUTES.STUDIO.UNAUTHORIZED_TO_VIEW_PROJECT)
                  }
                  if (!project?.studioProjectsGetProject?.project?.id) {
                    return {
                      error: true,
                    }
                  }
                  return null
                },
                element: <Outlet />,
              },
            ],
          },
          {
            path: ENABLE_FABRIC ? 'classic' : 'create',
            lazy: () => import('@studio/routes/studio/routes/create'),
            children: [
              {
                path: '',
                element: <Outlet />,
                // NOTE: we do a lot of `break` statements in this loader so we always run the pages check toward the bottom
                // sometimes the editor can start without any pages, like if there is no template to load or no saved state.
                // in that case, we need to create a new page so the user can start designing.
                loader: async ({ params, request, context }) => {
                  // if we're coming from the preload route, we don't want to do anything
                  if (context?.__fromPreloadRoute) {
                    return null
                  }

                  const { searchParams } = new URL(request.url)
                  const isLoggedInLoader = getIsLoggedIn().catch(() => null)

                  // clear spark session data whenever we create a new project
                  const { clearSparkSessionData } = await import(
                    './components/navigation-bars/navigation-bar-spark-ai/spark-ai.context'
                  )
                  clearSparkSessionData()

                  const { getSavedStudioData } = await import(
                    '@studio/routes/studio/hooks/use-save-studio-data-login'
                  )

                  loadUnsavedProject: if (searchParams.get(saveStudioDataQueryParam) === 'true') {
                    const content = getSavedStudioData()

                    // if we fail, just load the editor with an empty state
                    if (!content) {
                      break loadUnsavedProject
                    }

                    if (await isLoggedInLoader) {
                      const documentData = await useStudioProjectsCreateProjectMutation
                        .fetcher({
                          input: {
                            content,
                          },
                        })()
                        .catch(() => null)

                      // [1/2] did it successfully save?
                      const projectId = documentData?.studioProjectsCreateProject?.project?.id

                      if (projectId) {
                        return redirect(`./${projectId}`)
                      }
                    }

                    // [2/2] otherwise if we failed to save the project, just load the state into the editor so the
                    // user did not loose any progress. then they can try and save again
                    const store = await getStore()

                    try {
                      store?.loadJSON(typeof content === 'string' ? JSON.parse(content) : content)
                      return null
                    } catch {}
                  }

                  // create a new project with preset template if it has query params matching
                  const contentTemplateId = searchParams.get('content_template_id')
                  loadTemplateFromId: if (contentTemplateId) {
                    type TemplateData = {
                      fileUrl?: string
                    }

                    const templateData = await getObjectById(contentTemplateId)
                      .then(data => data as TemplateData)
                      .catch(() => null)

                    if (templateData?.fileUrl) {
                      const template = await fetch(templateData.fileUrl)
                        .then(data => data.json())
                        .catch(() => null)

                      if (!template) {
                        break loadTemplateFromId
                      }

                      if (await isLoggedInLoader) {
                        const result = await useStudioProjectsCreateProjectMutation
                          .fetcher({
                            input: {
                              content: JSON.stringify(template),
                              editor: ProjectEditor.Polotno,
                            },
                          })()
                          .catch(() => null)

                        if (result?.studioProjectsCreateProject.project?.id) {
                          return redirect(`./${result.studioProjectsCreateProject.project.id}`)
                        }
                      }

                      const store = await getStore()

                      try {
                        store?.loadJSON(
                          typeof template === 'string' ? JSON.parse(template) : template,
                        )
                        return null
                      } catch {}
                    }
                  }

                  const templateCategory = searchParams.get('template_category')
                  const templateId = searchParams.get('template_id')

                  loadTemplateFromCategory: if (templateCategory && templateId) {
                    const storeLoader = getStore()

                    const documentCategories = await import(
                      './components/new-design-button/context/document-categories'
                    ).then(m => [...m.DOCUMENT_CATEGORIES, ...m.PARTNER_CATEGORIES])

                    const templates = documentCategories.find(
                      category => category.id === templateCategory,
                    )?.templates

                    const template = templates?.find(
                      template => template.id === contentTemplateId || template.id === templateId,
                    )

                    if (!template) {
                      break loadTemplateFromCategory
                    }

                    const { generateNewDocument } = await import(
                      '@studio/editors/polotno/utils/polotno'
                    )

                    const newDocument = generateNewDocument(template)

                    if (!newDocument) {
                      break loadTemplateFromCategory
                    }

                    // if the user is logged in, create a new project for them
                    if (await isLoggedInLoader) {
                      const result = await useStudioProjectsCreateProjectMutation
                        .fetcher({
                          input: {
                            content: JSON.stringify(newDocument),
                            editor: ProjectEditor.Polotno,
                          },
                        })()
                        .catch(() => null)

                      if (result?.studioProjectsCreateProject.project?.id) {
                        return redirect(`./${result.studioProjectsCreateProject.project.id}`)
                      }
                    }

                    // load the new document as a fallback if the user is
                    //  - not logged in
                    //  - or the above create mutation failed
                    const store = await storeLoader

                    try {
                      store?.loadJSON(newDocument)
                      return null
                    } catch {}
                  }

                  const { id = '' } = params
                  // if you're logged in with new, or user directly enters '/studio/create' and no new project has been created
                  // we need to create a new project in the background and then open the new dialog modal.
                  // if you're logged out, then it's fine
                  createProject: if (
                    (searchParams.get('new') !== null ||
                      (searchParams.get('new') === null && !id)) &&
                    (await isLoggedInLoader)
                  ) {
                    const documentCategories = await import(
                      './components/new-design-button/context/document-categories'
                    ).then(m => [...m.DOCUMENT_CATEGORIES, ...m.PARTNER_CATEGORIES])

                    const template = documentCategories
                      .find(category => category.id === 'standard')
                      ?.templates?.find(template => template.id === 'standard')

                    if (!template) {
                      break createProject
                    }

                    const { generateNewDocument } = await import(
                      '@studio/editors/polotno/utils/polotno'
                    )

                    const newDocument = generateNewDocument(template)
                    const content = JSON.stringify(newDocument)

                    if (!content) {
                      break createProject
                    }

                    const result = await useStudioProjectsCreateProjectMutation
                      .fetcher({
                        input: {
                          content,
                          editor: ProjectEditor.Polotno,
                        },
                      })()
                      .catch(() => null)

                    if (result?.studioProjectsCreateProject.project?.id) {
                      const url = new URL(window.location.href)
                      const nextUrl = replacePathname(
                        url,
                        ROUTES.STUDIO.PROJECT(result.studioProjectsCreateProject.project.id),
                      )
                      const relativeUrl = stripDomain(stripQueryParams(nextUrl, ['new']))
                      return redirect(relativeUrl)
                    }
                  }

                  const store = await getStore()

                  if (Array.isArray(store?.pages) && store?.pages.length === 0) {
                    store.addPage()
                  }

                  return null
                },
              },
              {
                path: ':id',
                element: <Outlet />,
                loader: async ({ params, context }) => {
                  if (context?.__fromPreloadRoute) {
                    return
                  }

                  const { clearSavedDocumentData } = await import(
                    '@studio/editors/polotno/utils/polotno'
                  )

                  clearSavedDocumentData()
                  const isLoggedIn = await getIsLoggedIn().catch(() => null)
                  const storeLoader = getStore()

                  if (!isLoggedIn) {
                    return redirect('..')
                  }

                  if (!params.id) {
                    return {
                      error: true,
                    }
                  }

                  const variables = {
                    projectId: params.id,
                  }

                  const documentData = await queryClient.fetchQuery({
                    queryFn: useStudioProjectsGetProjectQuery.fetcher(variables),
                    queryKey: useStudioProjectsGetProjectQuery.getKey(variables),
                    staleTime: ms('15s'),
                  })

                  if (
                    documentData.studioProjectsGetProject.project?.activeRevision?.editor ===
                    ProjectEditor.Fabric
                  ) {
                    if (ENABLE_FABRIC) {
                      return redirect(`/studio/create/${params.id}`)
                    } else {
                      return redirect(`/studio/fabricate/${params.id}`)
                    }
                  }

                  const fileUrl =
                    documentData?.studioProjectsGetProject.project?.activeRevision.fileUrl
                  const isUserUnauthorizedToViewProject =
                    documentData?.studioProjectsGetProject.errors?.[0]?.code === 'unauthorized'

                  if (isUserUnauthorizedToViewProject) {
                    return redirect(ROUTES.STUDIO.UNAUTHORIZED_TO_VIEW_PROJECT)
                  }

                  if (!fileUrl) {
                    return { error: true }
                  }

                  const documentDataString = await fetch(fileUrl)
                    .then(r => r.text())
                    .catch(() => null)

                  if (!documentDataString) {
                    return {
                      error: true,
                    }
                  }

                  const store = await storeLoader

                  if (!store) {
                    return {
                      error: true,
                    }
                  }

                  let storeData
                  try {
                    storeData = JSON.parse(documentDataString)
                  } catch {
                    return {
                      error: true,
                    }
                  }

                  if (!storeData) {
                    return {
                      error: true,
                    }
                  }

                  if (storeData?.type === 'graphic-template') {
                    const responseTemplate = await fetch(storeData?.fileUrl)
                      .then(data => data.json())
                      .catch(() => null)

                    if (!responseTemplate) {
                      return {
                        error: true,
                      }
                    }

                    store?.loadJSON(responseTemplate)
                  } else {
                    store?.loadJSON(storeData)
                  }

                  return {
                    name: documentData.studioProjectsGetProject.project?.name,
                  }
                },
              },
            ],
          },
        ],
      },
      {
        path: 'templates',
        lazy: () => import('@studio/routes/templates'),
        children: [
          {
            path: 'item/:slug?',
            lazy: () => import('@studio/routes/templates/item'),
          },
          {
            path: ':category?/:subcategory?',
            lazy: () => import('@studio/routes/templates/gallery'),
          },
        ],
      },
    ],
  },
]

const ENABLE_COMPONENTS_ROUTE = process.env.VITE_PUBLIC_ENV !== 'production'

if (ENABLE_COMPONENTS_ROUTE) {
  routes.unshift({
    path: '/_components',
    lazy: () => import('@studio/routes/_components'),
  })
}

export const router = createBrowserRouter(routes)

async function getStore() {
  return import('polotno-utils/store').then(m => m.store).catch(() => null)
}

async function redirectIfNotInTeam({ params }: { params: Params<string> }) {
  // get user
  const isLoggedIn = await getIsLoggedIn().catch(() => null)

  // if not logged in
  if (!isLoggedIn) {
    return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
  }

  const { teamId = '' } = params
  const isUserPartOfTeam = await isCurrentUserPartOfTeam(teamId)

  return isUserPartOfTeam ? null : redirect(ROUTES.STUDIO.HOME)
}

async function isCurrentUserPartOfTeam(teamId: string) {
  // get team
  const variables = {
    input: {
      teamId,
    },
  }
  const teamQuery = await queryClient.fetchQuery({
    queryFn: useTeamsGetQuery.fetcher(variables),
    queryKey: useTeamsGetQuery.getKey(variables),
  })
  const team = teamQuery.teamsGet.team
  const currentUserId = await getUserId().catch(() => null)

  if (!team?.members || !currentUserId) {
    return redirect(ROUTES.STUDIO.HOME)
  }

  // check if user is part of team
  const isCurrentUserPartOfTeam = Boolean(
    team?.members.find(({ member }) => member.id === currentUserId),
  )

  return isCurrentUserPartOfTeam
}

async function redirectToStudioCreateIfNotLoggedIn() {
  const isLoggedIn = await getIsLoggedIn().catch(() => null)

  if (!isLoggedIn) {
    return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
  }

  return null
}
