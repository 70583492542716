import { IconButton } from 'primitives/buttons/icon-button'
import { HomeIcon } from 'primitives/icons'
import { Link } from 'react-router-dom'
import { ROUTES } from '@studio/routes'

interface HomeButtonProps {
  url?: string
}
export function HomeButton({ url }: HomeButtonProps) {
  return (
    <IconButton aria-label="Go to home" variant="transparent" asChild className="w-[42px]">
      <Link to={url ?? ROUTES.STUDIO.HOME}>
        <HomeIcon className="w-[24px]" />
        <span className="sr-only">Go to home</span>
      </Link>
    </IconButton>
  )
}
