import { useQueryClient } from '@tanstack/react-query'
import { UserQuery, useUserQuery as useUserQueryBase } from 'codegen/generated/user'
import ms from 'ms'
import * as React from 'react'

export type User = NonNullable<NonNullable<UserQuery['me']>['user']>

export const userQueryOptions = {
  keepPreviousData: true,
  staleTime: ms('15s'),
}

export function useUserQuery() {
  return useUserQueryBase(undefined, {
    ...userQueryOptions,
    select(data) {
      return data.me?.user
    },
  })
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  return children
}

export function useSetCurrentUserQuery() {
  const queryClient = useQueryClient()

  function setUserQuery(user: UserQuery) {
    queryClient.setQueryData<UserQuery>(useUserQueryBase.getKey(), user)
  }

  return { setUserQuery }
}
