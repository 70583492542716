function getClientSecrets() {
  if (typeof window === 'undefined') {
    return {}
  }

  const urlParams = new URLSearchParams(window.location.search)

  const setupIntentClientSecret = urlParams.get('setup_intent_client_secret')
  const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret')
  const userId = urlParams.get('cf_user_id')
  const tax = urlParams.get('cf_tax')
  const currency = urlParams.get('cf_currency')
  const subscriptionType = urlParams.get('cf_subscription_type')
  const subscriptionId = urlParams.get('cf_subscription_id')
  const id = urlParams.get('payment_intent') || urlParams.get('setup_intent')

  if (setupIntentClientSecret || paymentIntentClientSecret) {
    // clear url params after reading them
    window.history.replaceState({}, '', window.location.pathname)
  }

  return {
    setupIntentClientSecret,
    paymentIntentClientSecret,
    userId,
    tax,
    currency,
    subscriptionType,
    subscriptionId,
    id,
  }
}

export const clientSecrets = getClientSecrets()
