import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import ms from 'ms'
import { tw } from 'utils/classnames'

const DEFAULT_TOOLTIP_DELAY = ms('0.5s')

type TooltipProps = React.ComponentProps<typeof TooltipPrimitive.Root> & {
  children: React.ReactNode
  value: React.ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
  sideOffset?: number
  delay?: number
  open?: boolean
  className?: string
  arrowClassName?: string
  avoidCollisions?: boolean
  hideArrow?: boolean
}

function Arrow({ className }: { className?: string }) {
  return (
    <svg width="24" height="7" viewBox="0 0 24 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 9.1716 4.6716 L 6.3431 1.8432 C 4.8429 0.3429 2.808 -0.5 0.6863 -0.5 H 23.3137 C 21.192 -0.5 19.1571 0.3429 17.6569 1.8431 L 14.8284 4.6716 C 13.2663 6.2337 10.7337 6.2337 9.1716 4.6716 Z"
        className={tw('fill-gray-50', className)}
      />
    </svg>
  )
}

export function Tooltip({
  children,
  value,
  side = 'bottom',
  sideOffset = 0,
  delay = DEFAULT_TOOLTIP_DELAY,
  hideArrow = false,
  open,
  className,
  arrowClassName,
  avoidCollisions,
}: TooltipProps) {
  return (
    <TooltipPrimitive.Provider delayDuration={delay}>
      <TooltipPrimitive.Root open={open}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          side={side}
          sideOffset={sideOffset}
          className={tw(
            'rounded-2 text-12 min-h-[34px] translate-y-px bg-gray-50 px-2 py-3 text-gray-900',
            className,
          )}
          avoidCollisions={avoidCollisions}
        >
          {value}
          {hideArrow ? null : (
            <TooltipPrimitive.Arrow asChild>
              <Arrow className={arrowClassName} />
            </TooltipPrimitive.Arrow>
          )}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
