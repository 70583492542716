import { TopAppBar } from '@studio/components/top-app-bar'
import { motion } from 'framer-motion'
import { SpinnerIcon } from 'primitives/icons'
import { StudioIconLogo } from 'primitives/icons/local'

export function PageLoader() {
  return (
    <section className={'flex h-dvh w-dvw flex-col'}>
      <TopAppBar />
      <div className="shadow-3-smooth-inset grow">
        <motion.div
          className="flex h-full flex-col items-center justify-center gap-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5 }}
        >
          <StudioIconLogo className="h-auto w-[75px]" />
          <SpinnerIcon className="w-4 animate-spin" />
        </motion.div>
      </div>
    </section>
  )
}
