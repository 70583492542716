export const SENTRY_DSN_STUDIO =
  'https://6c71bdc0042790e4ea0b514fdb42a1fb@o1051960.ingest.sentry.io/4505964310495233'
export const SENTRY_DSN_PAYMENTS =
  'https://1e6db961a54d5b6959e674837618abc0@o1051960.ingest.sentry.io/4506433930133504'
export const SENTRY_DSN_WEB =
  'https://9f0f8ab5343b6a0f1fb937a3171f40e1@o1051960.ingest.us.sentry.io/4507231454298112'

export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // attempt to fix https://sentry.io/organizations/creative-fabrica/issues/3728633626
  /api.amplitude/i,
  /miscellaneous_bindings/i,
  /jid1-ejhbjdxm9zr4tq/i,
  // ignore clarity
  /clarity\.js/i,
  // ignore localhost
  /localhost/i,
]

export const clientIgnoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // error from the Instagram browser that can be safely ignored
  'ResizeObserver loop limit exceeded',
  'Invalid or expired OTP',
  // fullscreen issues, safely ignore this.
  "Failed to execute 'exitFullscreen' on 'Document': Document not active",
  // common error usually when a client fails to load a code split chunk because of network errors
  // or because we redeployed something.
  'Failed to fetch dynamically imported module',
  'error loading dynamically imported module',
  'Importing a module script failed.',
  // https://creative-fabrica.sentry.io/issues/4710743017
  // it's a js bug from cloudflare. part of their JS checker injection, sometimes it throws this error.
  "Cannot read properties of null (reading 'document')",
  // if someone is blocking bing
  'UET is not defined',
  // algolia timeout issues, okay to ignore since we didn't cause it
  'Unreachable hosts - your application id may be incorrect',
  // blocked frame warning (usually extensions)
  'Blocked a frame with origin "https://studio.creativefabrica.com"',
  // https://creative-fabrica.sentry.io/issues/4710807982
  // it's a missing algolia index error which usually means that algolia is having issues
  'Uncaught, unspecified "error" event. ([object Object])',
  // https://creative-fabrica.sentry.io/issues/4715102039
  // someone's custom extension perhaps? it's not in our codebase
  "Identifier 'originalPrompt' has already been declared",
  // https://creative-fabrica.sentry.io/issues/4711290505
  // unknown origin, not in our codebase. so i'll ignore
  'loadingAfgInterstitial is not defined',
  // https://creative-fabrica.sentry.io/issues/4711160524/
  // seems to be a chrome extension. and not in the codebase, so ignoring
  'tgetT is not defined',
  //https://developers.facebook.com/community/threads/320013549791141/
  "Can't find variable: _AutofillCallbackHandler",
  //https://techcommunity.microsoft.com/t5/discussions/error-can-t-find-variable-logmutedmessage-on-edge-mobile/m-p/3912307
  "Can't find variable: logMutedMessage",
]

// this used to be an enum, but it started to break the build. i turned it into
// an object, and this still broke the build. so now i'm just using strings and it seems to work.
export const SentryFilterTagPaymentsDialog = 'PaymentsDialog'
export const SentryFilterTagOneClickUpgradeButton = 'OneClickUpgradeButton'
export const SentryFilterTagOneClickUpgradeToggle = 'OneClickUpgradeToggle'
export const SentryFilterTagOneClickYearlyExtension = 'OneClickYearlyExtension'
