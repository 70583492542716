import { getPreference, setPreference } from '@studio/utils/dark-mode'
import * as React from 'react'

const DarkModeContext = React.createContext<{
  preference: 'dark' | 'light'
  togglePreference(): void
  setPreference(value: 'dark' | 'light'): void
}>(null!)

export function DarkModeProvider({ children }: { children: React.ReactNode }) {
  const [preference, setPreferenceLocal] = React.useState(getPreference())

  function togglePreference() {
    const nextPreference = preference === 'dark' ? 'light' : 'dark'
    setPreferenceLocal(nextPreference)
    setPreference(nextPreference)
  }

  function setPreferenceProxy(preference: 'dark' | 'light') {
    setPreferenceLocal(preference)
    setPreference(preference)
  }

  return (
    <DarkModeContext.Provider
      value={{
        preference,
        togglePreference,
        setPreference: setPreferenceProxy,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  )
}

export function useDarkMode() {
  return React.useContext(DarkModeContext)
}
