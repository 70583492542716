import { useSetPaymentsDialogVisibility } from '@studio/components/app-dialogs'
import { AppSwitchBar } from '@studio/components/top-app-bar/components/app-switch-bar'
import { HomeButton } from '@studio/components/top-app-bar/components/home-button'
import { ROUTES } from '@studio/routes'
import { useUserSubscriptionsGraphicsQuery } from 'codegen/generated/user'
import { StudioButton } from 'components/studio-button'
import 'payments-dialog/styles.css'
import { Stars01Icon } from 'primitives/icons'
import * as React from 'react'
import * as nanny from 'react-nanny'
import { useLocation } from 'react-router-dom'
import { tw } from 'utils/classnames'

function TopAppBarRight({ children }: { children?: React.ReactNode }) {
  return <>{children}</>
}

function TopAppBarLeft({ children }: React.PropsWithChildren) {
  return children
}

function TopAppBarCenter({ children }: { children?: React.ReactNode }) {
  return <>{children}</>
}

type Variant = 'studio' | 'vectorizer' | 'transfer' | 'flow'

interface TopAppBarRootProps {
  variant?: Variant
}
function TopAppBarRoot(props: React.ComponentProps<'header'> & TopAppBarRootProps) {
  const right = nanny.getChildrenByTypeDeep(props.children, [TopAppBarRight])
  const left = nanny.getChildrenByTypeDeep(props.children, [TopAppBarLeft])
  const center = nanny.getChildrenByTypeDeep(props.children, [TopAppBarCenter])
  const userSubscriptionsGraphicsQuery = useUserSubscriptionsGraphicsQuery(undefined, {
    keepPreviousData: true,
    select: data => Boolean(data.me?.user?.subscriptions?.graphics),
  })
  const isSubscribed = userSubscriptionsGraphicsQuery.data || false
  const isShowGoProForFreeButton = userSubscriptionsGraphicsQuery.isFetched && !isSubscribed
  const openPaymentsModal = useSetPaymentsDialogVisibility()
  const { pathname } = useLocation()
  const isStudioCreatePage = pathname.includes(ROUTES.STUDIO.NEW_PROJECT)

  return (
    <header
      className={tw(
        'flex h-[56px] items-center justify-between gap-[7px] border-b border-b-gray-800 bg-gray-900 py-[7px] pl-[7px] pr-[12px] sm:pr-[20px]',
        props.className,
      )}
    >
      <div className="flex h-[56px] items-center gap-4">
        <div className="flex items-center gap-1">
          {/* on mobile, home button should link to root flow page */}
          <div className="contents sm:hidden">
            <HomeButton url={props?.variant === 'flow' ? ROUTES.FLOW.ROOT : undefined} />
          </div>
          <div className="hidden sm:contents">
            <HomeButton />
          </div>
          <div className={tw('inline', props?.variant === 'flow' && 'hidden sm:flex')}>
            <AppSwitchBar />
          </div>
        </div>
        <div className="flex items-center gap-2">{left}</div>
      </div>
      <React.Suspense fallback={null}>{center}</React.Suspense>
      <div className="ml-2 flex items-center gap-[10px] sm:ml-3">
        {isShowGoProForFreeButton ? (
          <StudioButton
            type="button"
            size="small"
            className={tw(
              'text-13 hidden items-center justify-center gap-1 whitespace-nowrap px-[10px] lg:flex',
              isStudioCreatePage && 'hidden min-[1400px]:flex',
            )}
            onClick={openPaymentsModal}
          >
            <Stars01Icon className="max-h-5 w-5 min-w-5 max-w-5" />
            <span
              className={tw(
                !isStudioCreatePage && 'hidden lg:flex',
                isStudioCreatePage && 'hidden min-[1450px]:inline',
              )}
            >
              Go pro for free
            </span>
            <span
              className={tw(
                !isStudioCreatePage && 'hidden',
                isStudioCreatePage && 'inline min-[1450px]:hidden',
              )}
            >
              Go pro
            </span>
          </StudioButton>
        ) : null}
        {right}
      </div>
    </header>
  )
}

export const TopAppBar = Object.assign(TopAppBarRoot, {
  Right: TopAppBarRight,
  Left: TopAppBarLeft,
  Center: TopAppBarCenter,
})
