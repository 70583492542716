import * as Dialog from 'primitives/dialog'

import { clientSecrets } from '../lib/client-secrets'

type RootProps = React.ComponentProps<typeof Dialog.Root>

export function usePaymentsCompleteScreenDialog() {
  return {
    getRootProps(props?: RootProps) {
      return {
        defaultOpen: Boolean(
          clientSecrets.paymentIntentClientSecret || clientSecrets.setupIntentClientSecret,
        ),
        ...props,
      }
    },
  }
}
