/* 👻🚨👻🚨👻 Let op! This code has been auto generated, so best not to touch it 👻🚨👻🚨👻 */
import * as Types from './types';

import type { FetchOptions } from 'codegen/fetcher/fetcher.types'
import { fetcher } from 'codegen/fetcher'
import { useQuery, type UseQueryOptions, useMutation, type UseMutationOptions } from '@tanstack/react-query'
import { useSubscription, type UseSubscriptionArgs } from 'codegen/hooks/use-subscription'
export type VectorizerCreateSignedUploadUrlMutationVariables = Types.Exact<{
  input: Types.VectorizerCreateSignedUploadUrl;
}>;


export type VectorizerCreateSignedUploadUrlMutation = { __typename?: 'Mutation', vectorizerCreateSignedUploadUrl: { __typename?: 'VectorizerCreateSignedUploadUrlResponse', id: string, signedUrl: string, errors?: Array<{ __typename?: 'ErrorMessage', message: string }> | null } };

export type VectorizerCreateImageMutationVariables = Types.Exact<{
  input: Types.VectorizerCreateInput;
}>;


export type VectorizerCreateImageMutation = { __typename?: 'Mutation', vectorizerCreateImage: { __typename?: 'VectorizerCreateImageResponse', id?: string | null, errors?: Array<{ __typename?: 'ErrorMessage', message: string }> | null } };

export type VectorizerImageUpdatesSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type VectorizerImageUpdatesSubscription = { __typename?: 'Subscription', vectorizerImageUpdates: { __typename?: 'VectorizerImage', id: string, name?: string | null, status: Types.StatusType, vectorizedUrl?: string | null, originalUrl?: string | null } };

export type VectorizerGetImageQueryVariables = Types.Exact<{
  input: Types.VectorizerGetImageInput;
}>;


export type VectorizerGetImageQuery = { __typename?: 'Query', vectorizerGetImage: { __typename?: 'VectorizerGetImageResponse', image?: { __typename?: 'VectorizerImage', id: string, name?: string | null, status: Types.StatusType, originalUrl?: string | null, vectorizedUrl?: string | null, thumbnailUrl?: string | null } | null, errors?: Array<{ __typename?: 'ErrorMessage', message: string }> | null } };

export type VectorizerListImagesV2QueryVariables = Types.Exact<{
  input: Types.VectorizerListImagesInput;
}>;


export type VectorizerListImagesV2Query = { __typename?: 'Query', vectorizerListImagesV2: { __typename?: 'VectorizerListImagesV2Response', hasMore?: boolean | null, images?: Array<{ __typename?: 'VectorizerImage', id: string, name?: string | null, status: Types.StatusType, thumbnailUrl?: string | null }> | null, errors?: Array<{ __typename?: 'ErrorMessage', message: string, code: Types.ErrorCode }> | null } };

export type VectorizerDeleteAllImagesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type VectorizerDeleteAllImagesMutation = { __typename?: 'Mutation', vectorizerDeleteAllImages: { __typename?: 'VectorizerDeleteAllImagesResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', message: string }> | null } };

export type VectorizerDeleteImageMutationVariables = Types.Exact<{
  input: Types.VectorizerDeleteImageInput;
}>;


export type VectorizerDeleteImageMutation = { __typename?: 'Mutation', vectorizerDeleteImage: { __typename?: 'VectorizerDeleteImageResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', message: string }> | null } };

export type VectorizerCancelVectorizationMutationVariables = Types.Exact<{
  input: Types.VectorizerCancelImageVectorizationInput;
}>;


export type VectorizerCancelVectorizationMutation = { __typename?: 'Mutation', vectorizerCancelVectorization: { __typename?: 'VectorizerCancelImageVectorizationResponse', success?: boolean | null, errors?: Array<{ __typename?: 'ErrorMessage', message: string }> | null } };

export type VectorizerPublishImageMutationVariables = Types.Exact<{
  input: Types.VectorizerPublishImageInput;
}>;


export type VectorizerPublishImageMutation = { __typename?: 'Mutation', vectorizerPublishImage: { __typename?: 'VectorizerPublishImageResponse', upload?: { __typename?: 'StudioUploadsFile', id: string, folderId: string, url: string, type: Types.StudioUploadsAssetType, updatedAt?: string | null } | null, errors?: Array<{ __typename?: 'ErrorMessage', message: string }> | null } };


export const VectorizerCreateSignedUploadUrlDocument = `
mutation vectorizerCreateSignedUploadUrl($input: VectorizerCreateSignedUploadUrl!) {
  vectorizerCreateSignedUploadUrl(input: $input) {
    id
    signedUrl
    errors {
      message
    }
  }
}
`

export const vectorizerCreateSignedUploadUrlMutationKey = 'vectorizerCreateSignedUploadUrl'

export function vectorizerCreateSignedUploadUrlMutationFetcher(variables: VectorizerCreateSignedUploadUrlMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerCreateSignedUploadUrlMutation, VectorizerCreateSignedUploadUrlMutationVariables>(VectorizerCreateSignedUploadUrlDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useVectorizerCreateSignedUploadUrlMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<VectorizerCreateSignedUploadUrlMutation, E, VectorizerCreateSignedUploadUrlMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<VectorizerCreateSignedUploadUrlMutation, E, VectorizerCreateSignedUploadUrlMutationVariables, C>({
        ...options,
        mutationKey: [vectorizerCreateSignedUploadUrlMutationKey],
        mutationFn: (variables: VectorizerCreateSignedUploadUrlMutationVariables) => vectorizerCreateSignedUploadUrlMutationFetcher(variables, fetchOptions),
    })
}

export const VectorizerCreateImageDocument = `
mutation vectorizerCreateImage($input: VectorizerCreateInput!) {
  vectorizerCreateImage(input: $input) {
    id
    errors {
      message
    }
  }
}
`

export const vectorizerCreateImageMutationKey = 'vectorizerCreateImage'

export function vectorizerCreateImageMutationFetcher(variables: VectorizerCreateImageMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerCreateImageMutation, VectorizerCreateImageMutationVariables>(VectorizerCreateImageDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useVectorizerCreateImageMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<VectorizerCreateImageMutation, E, VectorizerCreateImageMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<VectorizerCreateImageMutation, E, VectorizerCreateImageMutationVariables, C>({
        ...options,
        mutationKey: [vectorizerCreateImageMutationKey],
        mutationFn: (variables: VectorizerCreateImageMutationVariables) => vectorizerCreateImageMutationFetcher(variables, fetchOptions),
    })
}

export const VectorizerDeleteAllImagesDocument = `
mutation vectorizerDeleteAllImages {
  vectorizerDeleteAllImages {
    success
    errors {
      message
    }
  }
}
`

export const vectorizerDeleteAllImagesMutationKey = 'vectorizerDeleteAllImages'

export function vectorizerDeleteAllImagesMutationFetcher(variables?: VectorizerDeleteAllImagesMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerDeleteAllImagesMutation, VectorizerDeleteAllImagesMutationVariables>(VectorizerDeleteAllImagesDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useVectorizerDeleteAllImagesMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<VectorizerDeleteAllImagesMutation, E, VectorizerDeleteAllImagesMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<VectorizerDeleteAllImagesMutation, E, VectorizerDeleteAllImagesMutationVariables, C>({
        ...options,
        mutationKey: [vectorizerDeleteAllImagesMutationKey],
        mutationFn: (variables?: VectorizerDeleteAllImagesMutationVariables) => vectorizerDeleteAllImagesMutationFetcher(variables, fetchOptions),
    })
}

export const VectorizerDeleteImageDocument = `
mutation vectorizerDeleteImage($input: VectorizerDeleteImageInput!) {
  vectorizerDeleteImage(input: $input) {
    success
    errors {
      message
    }
  }
}
`

export const vectorizerDeleteImageMutationKey = 'vectorizerDeleteImage'

export function vectorizerDeleteImageMutationFetcher(variables: VectorizerDeleteImageMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerDeleteImageMutation, VectorizerDeleteImageMutationVariables>(VectorizerDeleteImageDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useVectorizerDeleteImageMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<VectorizerDeleteImageMutation, E, VectorizerDeleteImageMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<VectorizerDeleteImageMutation, E, VectorizerDeleteImageMutationVariables, C>({
        ...options,
        mutationKey: [vectorizerDeleteImageMutationKey],
        mutationFn: (variables: VectorizerDeleteImageMutationVariables) => vectorizerDeleteImageMutationFetcher(variables, fetchOptions),
    })
}

export const VectorizerCancelVectorizationDocument = `
mutation vectorizerCancelVectorization($input: VectorizerCancelImageVectorizationInput!) {
  vectorizerCancelVectorization(input: $input) {
    success
    errors {
      message
    }
  }
}
`

export const vectorizerCancelVectorizationMutationKey = 'vectorizerCancelVectorization'

export function vectorizerCancelVectorizationMutationFetcher(variables: VectorizerCancelVectorizationMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerCancelVectorizationMutation, VectorizerCancelVectorizationMutationVariables>(VectorizerCancelVectorizationDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useVectorizerCancelVectorizationMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<VectorizerCancelVectorizationMutation, E, VectorizerCancelVectorizationMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<VectorizerCancelVectorizationMutation, E, VectorizerCancelVectorizationMutationVariables, C>({
        ...options,
        mutationKey: [vectorizerCancelVectorizationMutationKey],
        mutationFn: (variables: VectorizerCancelVectorizationMutationVariables) => vectorizerCancelVectorizationMutationFetcher(variables, fetchOptions),
    })
}

export const VectorizerPublishImageDocument = `
mutation vectorizerPublishImage($input: VectorizerPublishImageInput!) {
  vectorizerPublishImage(input: $input) {
    upload {
      id
      folderId
      url
      type
      updatedAt
    }
    errors {
      message
    }
  }
}
`

export const vectorizerPublishImageMutationKey = 'vectorizerPublishImage'

export function vectorizerPublishImageMutationFetcher(variables: VectorizerPublishImageMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerPublishImageMutation, VectorizerPublishImageMutationVariables>(VectorizerPublishImageDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useVectorizerPublishImageMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<VectorizerPublishImageMutation, E, VectorizerPublishImageMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<VectorizerPublishImageMutation, E, VectorizerPublishImageMutationVariables, C>({
        ...options,
        mutationKey: [vectorizerPublishImageMutationKey],
        mutationFn: (variables: VectorizerPublishImageMutationVariables) => vectorizerPublishImageMutationFetcher(variables, fetchOptions),
    })
}

export const VectorizerGetImageDocument = `
query vectorizerGetImage($input: VectorizerGetImageInput!) {
  vectorizerGetImage(input: $input) {
    image {
      id
      name
      status
      originalUrl
      vectorizedUrl
      thumbnailUrl
    }
    errors {
      message
    }
  }
}
`

export const vectorizerGetImageQueryRootKey = 'vectorizerGetImage'

export function vectorizerGetImageQueryFetcher(variables: VectorizerGetImageQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerGetImageQuery, VectorizerGetImageQueryVariables>(VectorizerGetImageDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function vectorizerGetImageQueryKey(variables: VectorizerGetImageQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [vectorizerGetImageQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function vectorizerGetImageQueryOptions(
    variables: VectorizerGetImageQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<VectorizerGetImageQuery, unknown, VectorizerGetImageQuery, (VectorizerGetImageQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: vectorizerGetImageQueryKey(variables, fetchOptions),
        queryFn: vectorizerGetImageQueryFetcher(variables, fetchOptions),
    }
}

export function useVectorizerGetImageQuery<D = VectorizerGetImageQuery, E = unknown>(
    variables: VectorizerGetImageQueryVariables,
    options?: Omit<UseQueryOptions<VectorizerGetImageQuery, E, D, (VectorizerGetImageQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<VectorizerGetImageQuery, E, D, (VectorizerGetImageQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...vectorizerGetImageQueryOptions(variables, fetchOptions),
    })
}

export const VectorizerListImagesV2Document = `
query vectorizerListImagesV2($input: VectorizerListImagesInput!) {
  vectorizerListImagesV2(input: $input) {
    images {
      id
      name
      status
      thumbnailUrl
    }
    hasMore
    errors {
      message
      code
    }
  }
}
`

export const vectorizerListImagesV2QueryRootKey = 'vectorizerListImagesV2'

export function vectorizerListImagesV2QueryFetcher(variables: VectorizerListImagesV2QueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<VectorizerListImagesV2Query, VectorizerListImagesV2QueryVariables>(VectorizerListImagesV2Document, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function vectorizerListImagesV2QueryKey(variables: VectorizerListImagesV2QueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [vectorizerListImagesV2QueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function vectorizerListImagesV2QueryOptions(
    variables: VectorizerListImagesV2QueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<VectorizerListImagesV2Query, unknown, VectorizerListImagesV2Query, (VectorizerListImagesV2QueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: vectorizerListImagesV2QueryKey(variables, fetchOptions),
        queryFn: vectorizerListImagesV2QueryFetcher(variables, fetchOptions),
    }
}

export function useVectorizerListImagesV2Query<D = VectorizerListImagesV2Query, E = unknown>(
    variables: VectorizerListImagesV2QueryVariables,
    options?: Omit<UseQueryOptions<VectorizerListImagesV2Query, E, D, (VectorizerListImagesV2QueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<VectorizerListImagesV2Query, E, D, (VectorizerListImagesV2QueryVariables | FetchOptions | string)[]>({
        ...options,
        ...vectorizerListImagesV2QueryOptions(variables, fetchOptions),
    })
}


export function useVectorizerImageUpdatesSubscription(options: Omit<UseSubscriptionArgs<VectorizerImageUpdatesSubscription, VectorizerImageUpdatesSubscriptionVariables>, "subscription">) {
          return useSubscription<VectorizerImageUpdatesSubscription, VectorizerImageUpdatesSubscriptionVariables>({
            subscription: VectorizerImageUpdatesDocument,
            ...options,
          })
}

export const VectorizerImageUpdatesDocument = `subscription vectorizerImageUpdates($id: ID!) {
  vectorizerImageUpdates(id: $id) {
    id
    name
    status
    vectorizedUrl
    originalUrl
  }
}`