import { queryClient } from '@studio/query-client'
import { FetchQueryOptions } from '@tanstack/react-query'
import { getUserIdSelector, isLoggedInQuerySelector } from 'auth/selectors'
import { resolveCookie, resolveToken } from 'codegen/fetcher'
import { useUserAuthQuery, useUserQuery, type UserAuthQuery } from 'codegen/generated/user'
import ms from 'ms'
import { clearToken, isOnCFDomain, saveToken } from 'utils/auth'

const userFetcher = useUserQuery.fetcher()

export function getUser() {
  return queryClient.fetchQuery({
    queryFn: userFetcher,
    queryKey: useUserQuery.getKey(),
    staleTime: ms('20s'),
  })
}

const userAuthFetcher = useUserAuthQuery.fetcher()

export function getUserAuth(
  options: Omit<FetchQueryOptions<UserAuthQuery>, 'queryFn' | 'queryKey'> = {},
) {
  return queryClient.fetchQuery({
    queryFn: userAuthFetcher,
    queryKey: useUserAuthQuery.getKey(),
    staleTime: ms('20s'),
    ...options,
  })
}

export function getIsLoggedIn() {
  return getUserAuth().then(isLoggedInQuerySelector)
}

export function getUserId() {
  return getUserAuth().then(getUserIdSelector)
}

export async function checkAndHandleAuth() {
  // are you on a cf domain and somehow have a token in local storage? then let's get rid of it
  if (isOnCFDomain() && resolveToken()) {
    clearToken()
  }

  // we want to do one request first to ensure that if u are an anonymous user,
  // we don't have multiple tokens flying around.
  // if you are on a preview env, we want to also ensure that we save the
  // same token that we get from the first request.
  // https://creativefabrica.atlassian.net/browse/STU-3157?focusedCommentId=142438
  if (isOnCFDomain() ? !resolveCookie() : !resolveToken()) {
    const data = await getUserAuth().catch(() => null)

    // for preview envs we want to save the token
    if (!isOnCFDomain()) {
      data?.me?.user?.isTemporary && data?.me?.token && saveToken(data.me.token, 'login')
    }
  }
}
