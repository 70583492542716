import { Link as ReactRouterDomLink, LinkProps } from 'react-router-dom'
import * as React from 'react'
import { usePreloadRoute } from '@studio/hooks/use-preload-route'
import { callAll } from 'utils/call-all'

export const PreloadedLink = React.forwardRef(function Link(
  { preload = true, ...props }: LinkProps & { preload?: boolean },
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const { preloadRoute } = usePreloadRoute({ to: props.to, enabled: preload })

  React.useEffect(() => {
    preloadRoute?.()
  }, [preloadRoute])

  return (
    <ReactRouterDomLink
      ref={ref}
      {...props}
      onMouseEnter={callAll(props.onMouseEnter, preloadRoute)}
    />
  )
})
