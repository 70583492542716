import * as Sentry from '@sentry/react'
import { isNetworkError } from 'utils/js'

import {
  SentryFilterTagOneClickUpgradeButton,
  SentryFilterTagOneClickUpgradeToggle,
  SentryFilterTagOneClickYearlyExtension,
  SentryFilterTagPaymentsDialog,
} from './constants'

const sentryPaymentTags = new Set([
  SentryFilterTagPaymentsDialog,
  SentryFilterTagOneClickUpgradeButton,
  SentryFilterTagOneClickUpgradeToggle,
  SentryFilterTagOneClickYearlyExtension,
])

export function beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
  const error = hint?.originalException
  if (isNetworkError(error)) {
    return null
  }

  return event
}

export function beforeSendPayments(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
  const nextEvent = beforeSend(event, hint)
  if (!nextEvent) {
    return null
  }

  if (event.tags?.component && sentryPaymentTags.has(event.tags.component as string)) {
    return event
  }

  return null
}
