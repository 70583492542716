import { initSentry } from 'sentry-utils'
import { SENTRY_DSN_STUDIO } from 'sentry-utils/constants'

const env = process.env.VITE_PUBLIC_ENV

initSentry({
  dsn: SENTRY_DSN_STUDIO,
  sentryOptions: {
    enabled: env !== undefined && env !== '' && process.env.TEST_BROWSER !== '1',
  },
})
