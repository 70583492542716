import { routes } from '@studio/router'
import * as React from 'react'
import { LinkProps, matchPath, type LoaderFunction } from 'react-router-dom'
import { nextFrame } from 'utils/dom'

type LazyFn = () => Promise<unknown> | undefined
const runnersMap = new Map<string, { lazyFns: LazyFn[]; loaderFns: LoaderFunction[] }>()

function findRunners(url: string, currentRoutes: typeof routes, currentPath: string) {
  const lazyFns: LazyFn[] = []
  const loaderFns: LoaderFunction[] = []

  currentRoutes.forEach(route => {
    // remove first slash from route.path
    const path = (currentPath + '/' + (route.path || '/')).replace('//', '/').replace(/\/$/, '')

    // let's not preload the 404 page lol
    const is404 = path === '/*'
    const isReferral = path.includes('/ref/')

    if (is404 || isReferral) {
      return
    }

    const match = !is404 && !!matchPath({ path, end: true, caseSensitive: false }, url)

    if (match) {
      if (route.lazy) {
        lazyFns.push(() => route.lazy?.())
      }
      if (route.loader) {
        loaderFns.push(route.loader)
      }
    }

    if (route.children) {
      const next = findRunners(url, route.children, path)
      lazyFns.push(...next.lazyFns)
      loaderFns.push(...next.loaderFns)
    }
  })

  return { lazyFns, loaderFns }
}

export function usePreloadRoute({
  to,
  enabled = true,
}: {
  to: LinkProps['to']
  enabled?: boolean
}) {
  return {
    preloadRoute: React.useCallback(() => {
      if (!enabled) {
        return
      }

      const url = typeof to === 'string' ? to : to.pathname || ''

      if (!runnersMap.has(url)) {
        const runners = findRunners(url, routes, '')
        runnersMap.set(url, runners)
      }

      const { lazyFns, loaderFns } = runnersMap.get(url)!

      nextFrame(() => {
        if (!requestIdleCallback) {
          return null
        }
        requestIdleCallback(() => {
          lazyFns?.forEach(lazy => lazy())
          loaderFns?.forEach(loader => {
            loader({
              params: {},
              request: new Request(url),
              context: {
                __fromPreloadRoute: true,
              },
            })
          })
        })
      })

      return {
        lazyFns,
        loaderFns,
      }
    }, [enabled, to]),
  }
}
